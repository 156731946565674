import { gql } from 'apollo-boost';

const getAllStudentVMGQL = gql`
  query students {
    students {
      studentId
      name
      ipAddress
      instructor {
        instructorId
        ipAddress
        name
      }
    }
  }
`;

export default getAllStudentVMGQL;
