import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Toolbar from './Toolbar';
import $ from '../../../styles/global';
import StudentView from './StudentView';
import {
  setUser,
  setInstructorView,
  clearStudentSession,
} from '../../../actions';
import useActions from '../../../utils/useActions';
import InstructorView from './InstructorView';

const Container = styled.div`
  ${({ split }) => `
    display: flex;
    flex-direction: row;
    width: 100%;

    > * {
      height: calc(100vh - 71px - 82px);
      &:first-child {
        ${
          split === '5050'
            ? `width: 50%; border-right: 1px solid ${$.color.blue1};`
            : ''
        }
        ${split === '1000' ? 'width: 100%;' : ''}
        ${
          split === '0100'
            ? 'z-index: -1; pointer-events: none; opacity: 0; width: 0%;'
            : ''
        }
      }

      &:last-child {
        ${split === '5050' ? 'width: 50%;' : ''}
        ${split === '0100' ? 'width: 100%;' : ''}
        ${
          split === '1000'
            ? 'z-index: -1; pointer-events: none; opacity: 0; width: 0%;'
            : ''
        }
      }
    }
  `}
`;

const Label = styled.div`
  ${({ split }) => `
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: ${$.color.gray1};
    text-align: center;
    font-family: Dosis Bold;
    font-size: 20px;

    > * {
      padding: ${$.layout().padding1}px 0;
      &:first-child {
        color: ${$.color.green};
        ${
          split === '5050'
            ? `width: 50%; border-right: 1px solid ${$.color.black1};`
            : ''
        }
        ${split === '1000' ? 'width: 100%;' : ''}
        ${
          split === '0100'
            ? 'z-index: -1; pointer-events: none; opacity: 0; width: 0%;'
            : ''
        }
      }

      &:last-child {
        color: ${$.color.orange4};
        ${split === '5050' ? 'width: 50%;' : ''}
        ${split === '0100' ? 'width: 100%;' : ''}
        ${
          split === '1000'
            ? 'z-index: -1; pointer-events: none; opacity: 0; width: 0%;'
            : ''
        }
      }
    }
  `}
`;

const VMClass = ({ setHasClass }) => {
  const [split, setSplit] = useState('5050');
  const state = useSelector(({ user, studentSession }) => {
    return { user, studentSession };
  });
  const [setCurrentUser, swapTo, clearCurrentSession] = useActions([
    setUser,
    setInstructorView,
    clearStudentSession,
  ]);

  /**
   * Sets up the class and listens to events emitted from WS.
   */
  useEffect(() => {
    const swapToCB = e => {
      /**
       * Do not swap if instructor is looking at our own VM.
       */
      if (e.detail.vm !== state.studentSession.studentVM) {
        swapTo(e.detail.vm);
      }
    };
    const endClassCB = () => {
      const ws = state.studentSession.wsInstance;

      if (ws) {
        ws.exit();
      }

      setHasClass(false);
      clearCurrentSession();
    };
    const checkFocusInterval = setInterval(() => {
      state.studentSession.wsInstance.focused({
        [state.studentSession.studentVM]: document.hasFocus(),
      });
    }, 3000);

    /**
     * Sets to true to enable UI elements.
     */
    setCurrentUser({ inClass: true });

    /**
     * Listens to incoming messages from websocket to swap views.
     * endClass - WS will emit this to tell class has ended.
     * swapTo - WS will emit this if instructorView needs to swap VM.
     */
    if (typeof window !== 'undefined') {
      window.addEventListener('endClass', endClassCB);
      window.addEventListener('swapTo', swapToCB);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('endClass', endClassCB);
        window.removeEventListener('swapTo', swapToCB);
        clearInterval(checkFocusInterval);
      }
    };
  }, []);

  return (
    <>
      <Toolbar
        setSplit={setSplit}
        instructorName={state.studentSession.instructor.name}
      />
      <ReactTooltip className="tooltip" />
      <Label split={split}>
        <div data-tip="This is your screen">Student</div>
        <div data-tip="This is your instructor's screen">Instructor</div>
      </Label>
      <Container split={split}>
        <StudentView split={split} />
        <InstructorView split={split} />
      </Container>
    </>
  );
};

VMClass.defaultProps = {
  setHasClass: () => {},
};

VMClass.propTypes = {
  setHasClass: PropTypes.func,
};

export default VMClass;
