import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import { getClassGQL } from '../apollo/student';
import SEO from '../components/seo';
import Loading from '../components/Loading';
import NoClass from '../components/student/NoClass';
import VMClass from '../components/student/VMClass';
import useActions from '../utils/useActions';
import { setStudentSession } from '../actions';
import Utils from '../utils';
import WS from '../utils/WS';
import $ from '../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ErrMessage = styled.div`
  font-size: 20px;
  line-height: 1.3em;
  text-align: center;
  color: ${$.color.red};
`;

const Index = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasClass, setHasClass] = useState(false);
  const [setCurrentSession] = useActions([setStudentSession]);
  const [redirecting, setRedirecting] = useState(false);
  const state = useSelector(({ user }) => {
    return { user };
  });
  const [getClass, { stopPolling }] = useLazyQuery(getClassGQL, {
    variables: {
      studentId: state.user.id,
    },
    pollInterval: 10000,
    onCompleted: ({ student }) => {
      let activeView;
      let ws;
      const getActiveViewCB = e => {
        activeView = e.detail.value;
      };

      setLoading(false);

      if (student) {
        setRedirecting(true);
        stopPolling();

        /**
         * Listens to this event once. The WS connection may emit this event when first connected.
         */
        window.addEventListener('useVMActiveView', getActiveViewCB, {
          once: true,
        });

        ws = new WS(state.user.role, student.twilioRoomName);
        ws.start();

        setTimeout(() => {
          setCurrentSession({
            activeView,
            wsInstance: ws,
            instructor: student.instructor,
            twilioRoomName: student.twilioRoomName,
            studentVM: student.ipAddress,
          });

          window.removeEventListener('useVMActiveView', getActiveViewCB);
          setHasClass(true);
          setRedirecting(false);
        }, 8000);
      }
    },
    onError: ({ message }) => {
      setError(message);
      setLoading(false);
    },
  });

  /**
   * Entry point. Kicks out anyone who is not a student before trying to query for a class.
   */
  useEffect(() => {
    Utils.redirect(state.user.role);
    getClass();
  }, []);

  return (
    <>
      <SEO
        title="Home | SG Code Campus Online App Platform"
        description="Learn to code in SG Code Campus's very own online learning platform."
      />
      {loading && (
        <Container>
          <Loading />
        </Container>
      )}
      {error && (
        <Container>
          <ErrMessage>
            {`An error occurred: `}
            <br />
            {error}
          </ErrMessage>
        </Container>
      )}
      {hasClass ? (
        <VMClass setHasClass={setHasClass} />
      ) : (
        <NoClass redirecting={redirecting} />
      )}
    </>
  );
};

export default Index;
