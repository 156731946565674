import { gql } from 'apollo-boost';

const getClassGQL = gql`
  query student($studentId: String!) {
    student(studentId: $studentId) {
      studentId
      name
      ipAddress
      twilioRoomName
      instructor {
        instructorId
        ipAddress
        name
      }
    }
  }
`;

export default getClassGQL;
