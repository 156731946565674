import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import $ from '../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  height: 100%;

  > * {
    transition: all 0.35s ${$.easingFn.standard};
    opacity: 0;
  }

  &.first {
    > :first-child {
      opacity: 1;
    }
  }

  &.second {
    > :first-child,
    > :nth-child(2) {
      opacity: 1;
    }
  }

  &.third {
    > * {
      opacity: 1;
    }
  }
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${$.color.blue3};
  border-radius: 99px;
`;

const Loading = () => {
  const [total, setTotal] = useState(0);
  const [name, setName] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setTotal(prev => {
        let newPrev = prev;

        if (newPrev > 3) {
          newPrev = 1;
        } else {
          newPrev += 1;
        }

        return newPrev;
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    switch (total) {
      case 1:
        setName('first');
        break;
      case 2:
        setName('second');
        break;
      case 3:
        setName('third');
        break;
      default:
        setName('');
        break;
    }
  }, [total]);

  return (
    <Container className={name}>
      <Circle />
      <Circle />
      <Circle />
    </Container>
  );
};

export default Loading;
