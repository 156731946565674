import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';
import { setUser } from '../../actions';
import useActions from '../../utils/useActions';
import Button from '../Button';

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${$.layout().margin5 * 2}px);
  text-align: center;
`;

const Message = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1.3em;
  margin: 128px 0 ${$.layout().margin2}px 0;
`;

const RefreshButton = styled(Button)``;

const NoClass = ({ redirecting }) => {
  const [setCurrentUser] = useActions([setUser]);

  useEffect(() => {
    /**
     * Set to false to enable/disable UI element specific to being in class.
     */
    setCurrentUser({ inClass: false });
  }, []);

  return (
    <Container>
      <Message>
        {redirecting
          ? 'Redirecting...'
          : 'You have no ongoing class now. You will be automatically redirected once the class is ready.'}
      </Message>
      {redirecting ? (
        ''
      ) : (
        <RefreshButton
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh now
        </RefreshButton>
      )}
    </Container>
  );
};

NoClass.defaultProps = {
  redirecting: false,
};

NoClass.propTypes = {
  redirecting: PropTypes.bool,
};

export default NoClass;
