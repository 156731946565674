import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Loading from '../../Loading';
import defaultSettings from '../../VNC/defaultSettings.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${$.color.gray2};
  position: relative;

  &.fullscreen {
    position: initial;
  }

  &.hidescreen {
    opacity: 0;
    pointer-events: none;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

/**
 * Ensures that this does not run until everything is mounted.
 * Prevents running during gatsby build. (Avoids window/document undefined)
 */
const LoadableVNC = loadable(() => import('../../VNC'));

const InstructorView = ({ split }) => {
  const state = useSelector(({ studentSession }) => {
    return { studentSession };
  });
  const [vncOptions, setVNCOptions] = useState({
    ...defaultSettings,
    viewOnly: true,
  });
  const [noVNCInstance, setNoVNCInstance] = useState(null);

  /**
   * Setup noVNC view.
   */
  useEffect(() => {
    const vm = state.studentSession.instructor.ipAddress;

    if (vm) {
      setVNCOptions(prev => {
        const newPrev = { ...prev };

        newPrev.host = Utils.getHostAndPort(vm).host;
        newPrev.port = Utils.getHostAndPort(vm).port;

        return newPrev;
      });
    }
  }, []);

  /**
   * Listens to active view changes and swaps video/noVNC connection.
   */
  useEffect(() => {
    const active = state.studentSession.activeView;

    setVNCOptions(prev => {
      const newPrev = { ...prev };

      newPrev.host = Utils.getHostAndPort(active).host;
      newPrev.port = Utils.getHostAndPort(active).port;

      if (noVNCInstance) {
        noVNCInstance.swap(newPrev);
      }

      return newPrev;
    });
  }, [state.studentSession.activeView]);

  return (
    <Container>
      {vncOptions.port && vncOptions.host ? (
        <LoadableVNC
          resize={split}
          options={vncOptions}
          noVNCInstance={noVNCInstance}
          setNoVNCInstance={setNoVNCInstance}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

InstructorView.propTypes = {
  split: PropTypes.string,
};

InstructorView.defaultProps = {
  split: '5050',
};

export default InstructorView;
