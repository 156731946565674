import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import Utils from '../../../utils';
import Loading from '../../Loading';
import defaultSettings from '../../VNC/defaultSettings.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${$.color.gray1};
`;

/**
 * Ensures that this does not run until everything is mounted.
 * Prevents running during gatsby build. (Avoids window/document undefined)
 */
const LoadableVNC = loadable(() => import('../../VNC'));

const StudentView = ({ split }) => {
  const [noVNCInstance, setNoVNCInstance] = useState(null);
  const [vncOptions, setVNCOptions] = useState(defaultSettings);
  const state = useSelector(({ studentSession }) => {
    return { studentSession };
  });

  /**
   * Setup noVNC connection.
   */
  useEffect(() => {
    const vm = state.studentSession.studentVM;

    if (vm) {
      setVNCOptions(prev => {
        const newPrev = { ...prev };

        newPrev.host = Utils.getHostAndPort(vm).host;
        newPrev.port = Utils.getHostAndPort(vm).port;

        return newPrev;
      });
    }
  }, []);

  return (
    <Container>
      {vncOptions.host && vncOptions.port ? (
        <LoadableVNC
          resize={split}
          options={vncOptions}
          noVNCInstance={noVNCInstance}
          setNoVNCInstance={setNoVNCInstance}
        />
      ) : (
        <Loading />
      )}
    </Container>
  );
};

StudentView.propTypes = {
  split: PropTypes.string,
};

StudentView.defaultProps = {
  split: '5050',
};

export default StudentView;
