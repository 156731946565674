import { Auth } from 'aws-amplify';
import TYPES from '../actions/types';

let timeout;
let counter = 0;

/**
 * class for websocket on the client side.
 * Self-reconnects once the connection is broken. It will stop trying to reconnect after failing three times.
 */
export default class WS {
  constructor(role, roomName) {
    this.ws = null;
    this.roomName = roomName;
    this.role = role;
    this.autoReconnect = true;
  }

  /**
   * Sends a message to server.
   * @param {string} type - event type
   * @param {object} payload - the message itself.
   */
  _send({ type, payload }) {
    const mssg = { type };

    if (typeof payload !== 'undefined') {
      mssg.payload = payload;
    }

    this.ws.send(JSON.stringify(mssg));
  }

  /**
   * Listens to the websocket opening the connection with the server.
   */
  _open() {
    if (this.ws === null) {
      return;
    }

    this.ws.onopen = () => {
      counter = 0;

      Auth.currentSession()
        .then(cred => {
          const payload = {
            roomName: this.roomName,
            token: cred.accessToken.jwtToken,
          };

          /**
           * Informs the server who this connection is.
           */
          switch (this.role) {
            case TYPES.INSTRUCTOR_ROLE:
              this._send({ type: 'INSTRUCTOR_CONNECT', payload });
              break;
            case TYPES.STUDENT_ROLE:
              this._send({ type: 'STUDENT_CONNECT', payload });
              break;
            default:
              break;
          }
        })
        .catch(() => {});
    };
  }

  /**
   * Listens to incoming messages from the server.
   */
  _message() {
    const dispatchEvent = (evt, detail) => {
      let customEvent;

      if (typeof detail !== 'undefined') {
        customEvent = new CustomEvent(evt, { detail });
      } else {
        customEvent = new CustomEvent(evt);
      }

      window.dispatchEvent(customEvent);
    };

    if (this.ws === null) {
      return;
    }

    this.ws.onmessage = e => {
      const mssg = JSON.parse(e.data);

      if (mssg) {
        switch (mssg.type) {
          case 'SWAP_TO':
            dispatchEvent('swapTo', mssg.payload);
            break;
          case 'FOCUSED':
            dispatchEvent('focused', mssg.payload);
            break;
          case 'CONNECTION_SUCCESSFUL':
            console.info('Successfully connected.');
            if (
              this.role === TYPES.STUDENT_ROLE &&
              mssg.payload &&
              mssg.payload.type === 'SWAP_TO'
            ) {
              dispatchEvent('useVMActiveView', { value: mssg.payload.value });
            }
            break;
          case 'ERROR':
            console.error('ERROR: ', mssg.payload);
            this.exit();
            break;
          case 'PING':
            if (this.ws && this.ws.readyState === 1) {
              this._send({ type: 'PONG' });
            }
            break;
          case 'END_CLASS':
            if (this.role === TYPES.STUDENT_ROLE) {
              dispatchEvent('endClass');
            }
            break;
          default:
            console.error(`Unknown type: ${mssg.type}`);
            break;
        }
      }
    };
  }

  /**
   * Listens to the websocket closing and attempt to reconnect.
   * 1. Stops reconnecting if all three attempts failed.
   * 2. Don't reconnect at all if autoConnect is false.
   */
  _close() {
    if (this.ws === null) {
      return;
    }

    this.ws.onclose = e => {
      // Every X minutes/seconds it will try to reconnect if found the connection to be closing.
      const time = 1000 * 5 + 1000;

      clearTimeout(timeout);

      if (this.autoReconnect) {
        if (counter < 3) {
          console.error('Restarting', e);
          timeout = setTimeout(() => {
            this.start();
          }, time * counter);
          counter += 1;
        } else {
          console.error('Unable to connect to server.');
        }
      }
    };
  }

  /**
   * Catches error from websocket. Prevents uncaught error problems.
   */
  _error() {
    if (this.ws === null) {
      return;
    }

    this.ws.onerror = e => {
      console.error('CLIENT WS ERROR: ', e);
    };
  }

  /**
   * Start listening for websockets event.
   */
  start() {
    // sgcc-online-platform-server.ap-southeast-1.elasticbeanstalk.com
    // babelhippo.com
    // wss://d2t80x6ek0wxou.cloudfront.net/
    // 'ws://sgcc-online-platform-server.ap-southeast-1.elasticbeanstalk.com'
    this.ws = new WebSocket(
      'ws://sgcc-opa-ws-env.ap-southeast-1.elasticbeanstalk.com'
    );

    this._open();
    this._message();
    this._error();
    this._close();
  }

  /**
   * Informs all students in same room to swap to VM view.
   */
  swapTo(vm) {
    if (
      this.role !== TYPES.INSTRUCTOR_ROLE ||
      (this.ws && this.ws.readyState !== 1) ||
      !this.ws
    ) {
      return;
    }

    this._send({
      type: 'SWAP_TO',
      payload: { roomName: this.roomName, vm },
    });
  }

  /**
   * Informs instructor that the student is slacking.
   */
  focused(vm) {
    if (
      this.role !== TYPES.STUDENT_ROLE ||
      (this.ws && this.ws.readyState !== 1) ||
      !this.ws
    ) {
      return;
    }

    this._send({
      type: 'FOCUSED',
      payload: { roomName: this.roomName, vm },
    });
  }

  /**
   * Informs all students that class has ended.
   */
  endClass() {
    if (
      this.role !== TYPES.INSTRUCTOR_ROLE ||
      (this.ws && this.ws.readyState !== 1) ||
      !this.ws
    ) {
      return;
    }

    this._send({ type: 'END_CLASS', payload: { roomName: this.roomName } });
  }

  /**
   * Prevents auto reconnecting and closes connection to the server immediately.
   */
  exit() {
    if (this.ws && this.ws.readyState === 1) {
      this.autoReconnect = false;
      this.ws.close();
    }
  }
}
