import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import $ from '../../../styles/global';

const Container = styled.div`
  padding: ${$.layout().padding3}px 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div`
  align-self: flex-start;
  font-size: 25px;
  font-family: Ruda Regular;
  letter-spacing: -1px;
  color: ${$.color.blue5};
`;

const SplitScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  > span {
    font-size: 16px;
  }

  > * {
    margin-right: ${$.layout().margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Dropdown = styled.select`
  outline: none;
`;

const Toolbar = ({ setSplit, instructorName }) => (
  <Container>
    <Title>{`Instructor: ${instructorName}`}</Title>
    <SplitScreenContainer>
      <ReactTooltip />
      <span data-tip="Change size of split screens">Split Screens:</span>
      <Dropdown
        onChange={e => {
          setSplit(e.target.value);
        }}
      >
        <option value="5050">Half screen view</option>
        <option value="0100">Instructor screen full view</option>
        <option value="1000">Student screen full view</option>
      </Dropdown>
    </SplitScreenContainer>
  </Container>
);

Toolbar.defaultProps = {
  setSplit: () => {},
  instructorName: '',
};

Toolbar.propTypes = {
  instructorName: PropTypes.string,
  setSplit: PropTypes.func,
};

export default Toolbar;
